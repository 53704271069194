import * as React from "react"
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Search from "../../components/SearchContainer";
import "../../sass/main.scss";
const SearchPage = () => {
    return (
      <>
        <Header
          title={"Search | East Side Enterprises"}
          description={"Search | East Side Enterprises"}
          lang={"EN"}
          showBackButton={true}
          bodyClasses={"page-type-search page-search"}
        />
        <main className="pt1">
          <section className={"c container"}>
            <div className={"c"}>
              <h1 className={"mt1"}>Search Results</h1>
            </div>
            <Search />
          </section>
        </main>
        <Footer />
      </>
    );
}

export default SearchPage;
