import locations from "./locations.json"
import {getMonthNumber} from "./helper-functions";

function replaceAll(haystack, needle, replacement) {
    while (haystack.indexOf(needle) >= 0) {
        haystack = haystack.replace(needle, replacement);
    }
    return haystack
}

const removeAccents = (str) => {
    const accents = {
        a: 'àáâãäåæ', c: 'ç', e: 'èéêëæ', i: 'ìíîï', n: 'ñ', o: 'òóôõöø', s: 'ß', u: 'ùúûü', y: 'ÿ'
    }
    let newStr = "";
    for (let char in str) {
        let continueFlag = false;
        for (let accent in accents) {
            if (accents[accent].includes(str[char])) {
                newStr += accent;
                continueFlag = true;
                break;
            }
        }
        if (continueFlag) continue;
        newStr += str[char];
    }
    return newStr;
};

// PositionListScrubber
// Merge and clean job information data with store information
const PositionListScrubber = (jobs, lang) => {
    let returnData = [];

    // If jobs are found
    if (jobs) {

        // Loop thru each Job
        for (let job in jobs) {

            // Skip flag, set to true to not add the job to the return list.
            let skipFlag = false;
            let jobData = {};

            if (jobs.hasOwnProperty(job)) {
                jobData = jobs[job];

                for (let key in jobData) {
                    if (jobData.hasOwnProperty(key)) {
                        jobData[key] = jobData[key]?.toString();
                    }
                }
                jobData.listDate = new Date(jobData.listDate).getTime();

                if (jobs[job].hasOwnProperty("Storenumber")) {
                    let storeNumber = jobs[job]["Storenumber"].toString();
                    let locationDetails = locations.find(sto => sto['storeIdentifier'].toString() === storeNumber);

                    // If a store is found, add the store information to the job object.
                    if (locationDetails !== undefined) {
                        jobData.Storenumber = storeNumber;
                        jobData.zip = locationDetails["ZIP"];
                        jobData.nearbyZipCodes = locationDetails["nearbyZipCodes"];
                        jobData.address = locationDetails['address'];
                        jobData.status = jobs[job]["Status"];
                        jobData.jobCategory = jobs[job]["jobcategory"];
                        jobData.shiftType = jobs[job]["shifttype"];
                        jobData.city = locationDetails["city"];
                        if(!jobData.shiftType){
                             jobData.shiftType = "All";
                        }
                        if(locationDetails.city === "na"){
                            jobData.city = locationDetails.address;
                            jobData.isCounty = true;
                        }
                        const url = new URL(jobs[job]["applyUrl"].toString())
                        const path = url.pathname;
                        const locale = url.searchParams.get("locale");
                        let careerText = "/careers";
                        let title = jobData.title;
                        let posPath = careerText + "/" + title + "-" + storeNumber + "-az-" + path.split("-")[0].replace("/processes/", "") + "-" + locale;
                        posPath = posPath.toLowerCase();
                        posPath = replaceAll(posPath, "-/ ", "-");
                        posPath = replaceAll(posPath, "-/ ", "-");
                        posPath = replaceAll(posPath, "   ", "-");
                        posPath = replaceAll(posPath, "  ", "-");
                        posPath = replaceAll(posPath, " ", "-");
                        posPath = replaceAll(posPath, ", ", "-");
                        posPath = replaceAll(posPath, ",", "");
                        posPath = replaceAll(posPath, "(", "");
                        posPath = replaceAll(posPath, "(", "");
                        posPath = replaceAll(posPath, ")", "");
                        posPath = replaceAll(posPath, "---", "-");
                        posPath = replaceAll(posPath, "--", "-");
                        posPath = replaceAll(posPath, "-/-", "-");
                        posPath = replaceAll(posPath, "-&-", "-");
                        posPath = removeAccents(posPath);
                        jobData.descUrl = posPath
                    }


                    // If no store is found in the master list, skip the job, do not add it to the list.
                    else {
                        skipFlag = true;
                    }
                } else {
                    skipFlag = true;
                }

            }
            if (!skipFlag) {
                returnData.push(jobData);
            }
        }

    }
    return returnData;
}
export default PositionListScrubber;
